import { ReactNode } from 'react';
import { Typography } from '@mui/material';

type Props = {
  title: string;
  color?: string;
  fontWeight?: string;
};

export function RulesBlockSubtitle(props: Readonly<Props>): ReactNode {
  return (
    <Typography
      variant='h1'
      component='p'
      id='rules-block-subtitle'
      sx={{
        fontSize: '1.3rem',
        fontWeight: props.fontWeight || '700',
        textAlign: 'left',
        lineHeight: '50px',
        color: props.color || 'primary',
        fontFamily: 'Neuton',
        letterSpacing: '0.0071428571em',
        '@media(max-width:5000px)': {
          fontSize: '2rem',
          lineHeight: '2.3rem',
        },

        '@media(max-width:3000px)': {
          fontSize: '1.5rem',
          lineHeight: '2rem',
        },

        '@media(max-width:1440px)': {
          fontSize: '1.25rem',
          lineHeight: '1.8rem',
        },

        '@media(max-width:800px)': {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      }}
    >
      {props.title}
    </Typography>
  );
}
