import { Box, Typography } from '@mui/material';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { Background } from '../_Layout/Background';
import { t } from 'i18next';

export function Error() {
  const error = useRouteError();

  console.error('Full error object:', error);

  const isError = (err: unknown): err is Error => {
    return err instanceof Error;
  };

  const getErrorDetails = (err: unknown): string => {
    if (isRouteErrorResponse(err)) {
      return JSON.stringify(
        {
          status: err.status,
          statusText: err.statusText,
          data: err.data,
        },
        null,
        2
      );
    }

    if (isError(err)) {
      return JSON.stringify(
        {
          message: err.message,
          name: err.name,
          stack: err.stack,
        },
        null,
        2
      );
    }

    return err.toString();
  };

  return (
    <>
      <Background />
      <Box
        style={{
          padding: '20px',
          height: '80vh',
          width: '100vw',

          fontFamily: 'Arial, sans-serif',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant='h1'
          sx={{
            fontFamily: 'Westmeath',
            color: '#eab354',
            fontSize: '3rem',
            '@media(max-width: 600px)': { fontSize: '2rem' },
          }}
        >
          {t('Components.Error.Title')}
        </Typography>
        <Typography
          variant='h2'
          sx={{
            fontFamily: 'Bebas',
            color: '#e7e4e3',
            marginBottom: '20px',
            fontSize: '1.5rem',

            '@media(max-width: 600px)': { fontSize: '1rem' },
          }}
        >
          {t('Components.Error.Caption')}
        </Typography>

        <details
          style={{
            backgroundColor: '#f4f4f4',
            padding: '10px',
            borderRadius: '5px',
            maxWidth: '80vw',
            wordBreak: 'break-word',
          }}
        >
          <summary> {t('Components.Error.Details')}</summary>
          <Typography variant='caption'>{getErrorDetails(error)}</Typography>
        </details>
      </Box>
    </>
  );
}
