import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  SxProps,
  Typography,
} from '@mui/material';

type Props = {
  id: string;
  checked: boolean;
  onChange?: (evt) => void;
  text?: string;
  sx?: SxProps;
};

export function ThestralCheckbox(props: Readonly<Props>) {
  return (
    <FormGroup>
      <FormControlLabel
        sx={{
          ...props.sx,
          color: '#fff',
          fontFamily: 'Neuton',
          '&:hover': {
            backgroundColor: '#303030',
          },
        }}
        onChange={props.onChange}
        control={
          <Checkbox
            id={props.id}
            checked={props.checked}
            sx={{
              '& .MuiSvgIcon-root': {
                color: 'primary.main',
                '@media(max-width:5000px)': {
                  width: '50px',
                  height: 'auto',
                },

                '@media(max-width:1440px)': {
                  width: '30px',
                  height: 'auto',
                },

                '@media(max-width:800px)': {
                  width: '20px',
                  height: 'auto',
                },
              },
            }}
          />
        }
        label={
          <Typography
            sx={{
              '@media(max-width:5000px)': {
                fontSize: '1.8rem',
                lineHeight: '2rem',
              },

              '@media(max-width:1440px)': {
                fontSize: '1.5rem',
                lineHeight: '1.8rem',
              },

              '@media(max-width:800px)': {
                fontSize: '1rem',
                lineHeight: '1.5rem',
              },
            }}
          >
            {props.text}
          </Typography>
        }
      />
    </FormGroup>
  );
}
