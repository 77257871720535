import { Grid } from '@mui/material';
import { RulesBox, RulesContent, RulesParagraph, TabHeadline } from 'components/thestral';
import { EVENTS_URL, STATIC_URL } from 'const';
import { parseDate, useFetch } from 'func';
import { Event } from 'types';

import Placeholder from 'assets/images/470x470.svg';
import { DateTime } from 'luxon';
import { t } from 'i18next';

export function PastCons() {
    const { data } = useFetch<Event[]>(EVENTS_URL);

    return (
        <>
            <TabHeadline title={t('Components.Rules.WorldBuilding.Cons')} />

            <RulesParagraph text={t('Components.Rules.WorldBuilding.ConsTitle')} sx={{whiteSpace: 'pre-line'}} />

            <Grid container spacing={2}>
                {data
                    ?.filter((i) => DateTime.fromISO(i.end) < DateTime.now())
                    .map((item: Event, index) => (
                        <RulesBox
                            key={item.id}
                            image={item.imagePath ? `${STATIC_URL}/uploads/${item.imagePath}` : Placeholder}
                            content={
                                <RulesContent
                                    title={item.name}
                                    text={item.summary}
                                    dates={`${parseDate(item.start)} - ${parseDate(item.end)}`}
                                    itDates={`${parseDate(item.itStart)} - ${parseDate(item.itEnd)}`}
                                    to={`${STATIC_URL}/uploads/${item.dailyProphet}`}
                                    con
                                />
                            }
                            reversed={index % 2 == 0}
                        />
                    ))}
            </Grid>
        </>
    );
}
