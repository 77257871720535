import { ReactNode } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { DoubleArrow } from '@mui/icons-material';

type Props = {
  text: string;
};

export function RulesListItem(props: Readonly<Props>): ReactNode {
  return (
    <ListItem
      sx={{
        padding: '0 0 0 16px',
        '& .MuiListItemText-primary': {
          fontSize: '1.5rem',
          fontFamily: 'Neuton',

          '@media(max-width:5000px)': {
            fontSize: '2rem',
            lineHeight: '2.3rem',
          },

          '@media(max-width:3000px)': {
            fontSize: '1.5rem',
            lineHeight: '2rem',
          },

          '@media(max-width:1440px)': {
            fontSize: '1.25rem',
            lineHeight: '1.8rem',
          },

          '@media(max-width:800px)': {
            fontSize: '1rem',
            lineHeight: '1.5rem',
          },
        },
      }}
    >
      <ListItemIcon
        sx={{
          color: '#ffffff',
          marginRight: '-20px',
        }}
      >
        <DoubleArrow />
      </ListItemIcon>
      <ListItemText>{props.text}</ListItemText>
    </ListItem>
  );
}
