import 'pdfjs-dist/build/pdf.worker.mjs';
import { createRoot } from 'react-dom/client';
import { reportWebVitals } from './reportWebVitals';
import 'config/i18n';

import { App } from './App';

import './index.css';
import { DevSupport } from '@react-buddy/ide-toolbox';
import { ComponentPreviews, useInitial } from './dev';

const root = createRoot(document.getElementById('root'));
root.render(
  <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
    <App />
  </DevSupport>
);

reportWebVitals();
