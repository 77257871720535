import { Box, CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Event } from 'types';
import { ThestralDataGrid } from '../ThestralDataGrid';
import { EMPTY } from 'const';
import { t } from 'i18next';
import { Dialog } from 'components/ui';

type Props = {
  open: boolean;
  onClose: () => void;
  event: Event;
};

export function TriggerDialog(props: Readonly<Props>) {
  const [trigger, setTrigger] = useState<{ id: number; value: string }[]>();
  const [undesired, setUndesired] = useState<{ id: number; value: string }[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.event) {
      const rowsTrigger = props.event.triggers.map((trigger, index) => ({
        id: index,
        value: trigger,
      }));

      const rowsUndesired = props.event.undesired.map((undesired, index) => ({
        id: index,
        value: undesired,
      }));

      setTrigger(rowsTrigger);
      setUndesired(rowsUndesired);
      setLoading(false);
    }
  }, [props.event]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={`${props.event?.name} // ${t('Components.Admin.Trigger.Title')}`}
      content={
        loading ? (
          <Box display='flex' justifyContent='center'>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container gap={2}>
            <Grid sm={5} xs={12}>
              <ThestralDataGrid
                rows={trigger || EMPTY}
                columns={[
                  {
                    field: 'value',
                    headerName: t('Components.Admin.Trigger.Trigger'),
                    sortable: true,
                    flex: 2,
                  },
                ]}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 25 },
                  },
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                  },
                }}
                loading={loading}
              />
            </Grid>
            <Grid sm={5} xs={12}>
              <ThestralDataGrid
                rows={undesired || EMPTY}
                columns={[
                  {
                    field: 'value',
                    headerName: t('Components.Admin.Trigger.Undesired'),
                    sortable: true,
                    flex: 2,
                  },
                ]}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 25 },
                  },
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                  },
                }}
                loading={loading}
              />
            </Grid>
          </Grid>
        )
      }
    />
  );
}
