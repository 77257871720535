import { ReactNode, useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Box, CircularProgress, MenuItem, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { BugReport } from '@mui/icons-material';

import { Button, Dialog, TextField } from 'components/ui';

import { HTTP_METHOD } from 'const';
import { useFetchFromBackend, useSnackbar } from 'func';

const STATIC_URL = process.env.REACT_APP_STATIC_URL;

const validationSchema = yup.object({
    title: yup.string().required(t('Components.Register.Required')),
    description: yup.string().required(t('Components.Register.Required')),
});

export function BugReportMenuItem(): ReactNode {
    const { showSnackbar } = useSnackbar();
    const fetchFromBackend = useFetchFromBackend();

    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
        },
        validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => sendBug(values),
    });

    function sendBug(values) {
        const body = JSON.stringify(values);

        setLoading(true);
        fetchFromBackend(`${STATIC_URL}/scripts/mail.php`, {
            method: HTTP_METHOD.POST,
            body,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.ok) {
                    showSnackbar(t('Components.BugReport.Snackbar.Success'), 'success');
                } else if (response.status === 400) {
                    showSnackbar(`${t('Components.BugReport.Snackbar.RequiredMissing')} (${response.status})`, 'error');
                } else {
                    showSnackbar(`${t('Components.BugReport.Snackbar.Error')} (${response.status})`, 'error');
                }
            })
            .catch((error) => {
                console.error(error);
                showSnackbar(`${t('Components.BugReport.Snackbar.Error')} (500)`, 'error');
            })
            .finally(() => {
                setLoading(false);
                setOpen(false);
            });
    }

    return (
        <>
            <MenuItem onClick={() => setOpen(true)}>
                <BugReport sx={{ marginRight: '32px' }} />
                <Typography variant='h6' component='span' className='font-bebas'>
                    <Trans i18nKey='Components.BugReport.Title' />
                </Typography>
            </MenuItem>

            <Dialog
                sx={{
                    '& .MuiPaper-root': {
                        bgcolor: '#1e1e1e',
                        marginBottom: '0',
                    },
                }}
                open={open}
                onKeyDown={(e) => e.stopPropagation()}
                onClose={() => setOpen(false)}
                title={t('Components.BugReport.Title')}
                caption={t('Components.BugReport.Dialog.Caption')}
                content={
                    loading ? (
                        <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <form onSubmit={formik.handleSubmit}>
                            <TextField id='title' label={t('Components.BugReport.Form.Title')} onChange={formik.handleChange} error={Boolean(formik.errors.title)} helperText={formik.errors.title as string} />
                            <TextField
                                label={t('Components.BugReport.Form.Description')}
                                id='description'
                                multiline
                                rows={15}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.description)}
                                helperText={formik.errors.description as string}
                            />
                        </form>
                    )
                }
                actions={<Button onClick={formik.submitForm} color='success' text={<Trans i18nKey='Components.BugReport.Dialog.BtnSend' />} />}
            />
        </>
    );
}
