import { useState } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { Button, DateField, Dialog, TextField } from 'components/ui';
import { CardCircularProgress } from 'components/thestral';

import { Event } from 'types';
import { useFetchFromBackend } from 'func';
import { EVENTS_URL, HTTP_METHOD } from 'const';
import { DateTime } from 'luxon';

type Props = {
  open: boolean;
  event: Event | null;
  onClose: () => void;
  triggerRefetch: () => void;
  showSnackbar?: (msg, severity) => void;
};

const validationSchema = yup.object({
  name: yup.string().required(t('Components.Register.Required')),
  place: yup.string().optional(),
  start: yup.date().required(t('Components.Register.Required')),
  end: yup.date().required(t('Components.Register.Required')),
  price1: yup.number().required(t('Components.Register.Required')),
  price2: yup.number().required(t('Components.Register.Required')),
  priceChangeDate: yup.date().required(t('Components.Register.Required')),
  priceChangeFinal: yup.date().required(t('Components.Register.Required')),
  mandateRef: yup.string().required(t('Components.Register.Required')),
  mandatePayDate: yup.string().required(t('Components.Register.Required')),
  itStart: yup.date().optional(),
  itEnd: yup.date().optional(),
});
export function EventDialog(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();
  const [loading, setLoading] = useState<boolean>();

  const saveEvent = (values) => {
    const body = JSON.stringify(values as Event);
    const id = props.event ? `/${props.event.id}` : '';

    fetchFromBackend(`${EVENTS_URL}${id}`, {
      method: props.event ? HTTP_METHOD.PATCH : HTTP_METHOD.POST,
      body,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;

        props.showSnackbar(
          `${t('Components.Admin.Events.Update')} ${result}`,
          severity
        );
        props.triggerRefetch();
        props.onClose();
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      name: props.event?.name ?? '',
      place: props.event?.place ?? '',
      start: props.event?.start ?? DateTime.now().toISODate(),
      end: props.event?.end ?? DateTime.now().toISODate(),
      price1: props.event?.price1 ?? '',
      price2: props.event?.price2 ?? '',
      priceChangeDate:
        props.event?.priceChangeDate ?? DateTime.now().toISODate(),
      priceChangeFinal:
        props.event?.priceChangeFinal ?? DateTime.now().toISODate(),
      mandateRef: props.event?.mandateRef ?? '',
      mandatePayDate: props.event?.mandatePayDate ?? DateTime.now().toISODate(),
      itStart: props.event?.itStart ?? '1999-01-01',
      itEnd: props.event?.itEnd ?? '1999-12-31',
    },
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: saveEvent,
  });

  const actions = () => {
    if (loading) return <></>;

    return (
      <Button
        onClick={formik.submitForm}
        color='success'
        sx={{ marginLeft: '5px' }}
        text={t('Components.Admin.Events.Save')}
      />
    );
  };

  const content = () => {
    if (loading) {
      return <CardCircularProgress />;
    }

    return (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id='name'
              label={t('Generic.Name')}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.name)}
              helperText={formik.errors.name as string}
              value={formik.values.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='place'
              label={t('Components.Events.Location')}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.place)}
              helperText={formik.errors.place as string}
              value={formik.values.place}
            />
          </Grid>

          <Grid item xs={6}>
            <DateField
              label={t('Components.Events.Start')}
              onChange={(value) =>
                formik.setFieldValue('start', value.toISODate())
              }
              value={formik.values.start}
              error={Boolean(formik.errors.start)}
              helperText={formik.errors.start as string}
              maxDate={formik.values.end}
              disablePast
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              label={t('Components.Events.End')}
              onChange={(value) =>
                formik.setFieldValue('end', value.toISODate())
              }
              value={formik.values.end}
              error={Boolean(formik.errors.end)}
              helperText={formik.errors.end as string}
              minDate={formik.values.start}
              disablePast
            />
          </Grid>

          <Grid item xs={6}>
            <DateField
              label={t('Components.Events.ItStart')}
              onChange={(value) =>
                formik.setFieldValue('itStart', value.toISODate())
              }
              value={formik.values.itStart}
              error={Boolean(formik.errors.itStart)}
              helperText={formik.errors.itStart as string}
              minDate={'1999-01-01'}
              maxDate={'1999-12-31'}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              label={t('Components.Events.ItEnd')}
              onChange={(value) =>
                formik.setFieldValue('itEnd', value.toISODate())
              }
              value={formik.values.itEnd}
              error={Boolean(formik.errors.itEnd)}
              helperText={formik.errors.itEnd as string}
              minDate={'1999-01-01'}
              maxDate={'1999-12-31'}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='price1'
              type='number'
              label={`${t('Components.Events.Price1')}`}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.price1)}
              helperText={formik.errors.price1 as string}
              value={formik.values.price1}
              sx={{ marginTop: '0px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='price2'
              label={`${t('Components.Events.Price2')}`}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.price2)}
              helperText={formik.errors.price2 as string}
              value={formik.values.price2}
              sx={{ marginTop: '0px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              label={t('Components.Events.PriceChange') + '1'}
              onChange={(value) =>
                formik.setFieldValue('priceChangeDate', value.toISODate())
              }
              value={formik.values.priceChangeDate}
              error={Boolean(formik.errors.priceChangeDate)}
              helperText={formik.errors.priceChangeDate as string}
              disablePast
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              label={t('Components.Events.PriceChange') + '2'}
              onChange={(value) =>
                formik.setFieldValue('priceChangeFinal', value.toISODate())
              }
              value={formik.values.priceChangeFinal}
              error={Boolean(formik.errors.priceChangeFinal)}
              helperText={formik.errors.priceChangeFinal as string}
              disablePast
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id='mandateRef'
              label={t('Components.Events.MandateReference')}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.mandateRef)}
              helperText={formik.errors.mandateRef as string}
              value={formik.values.mandateRef}
              sx={{ marginTop: '0px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              label={t('Components.Events.MandatePayDate')}
              onChange={(value) =>
                formik.setFieldValue('mandatePayDate', value.toISODate())
              }
              value={formik.values.mandatePayDate}
              error={Boolean(formik.errors.mandatePayDate)}
              helperText={formik.errors.mandatePayDate as string}
              disablePast
            />
          </Grid>
        </Grid>
      </form>
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={
        props.event?.id
          ? t('Components.Admin.Events.Edit')
          : t('Components.Admin.Events.New')
      }
      actions={actions()}
      content={content()}
    />
  );
}
