import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { LinkText } from 'components/ui';

type Props = {
  title: string;
  href?: string;
  id?: string;
};

export function RulesBlockTitle(props: Readonly<Props>): ReactNode {
  return (
    <Typography
      variant='h1'
      component='p'
      id={props.id}
      sx={{
        fontSize: '1.6rem',
        fontWeight: '400',
        textAlign: 'left',
        lineHeight: '2rem',
        color: '#ffffff',
        fontFamily: 'Bebas',
        '@media(max-width:5000px)': {
          fontSize: '2rem',
          lineHeight: '2.3rem',
        },
        '@media(max-width:3000px)': {
          fontSize: '1.5rem',
          lineHeight: '2rem',
        },
        '@media(max-width:1440px)': {
          fontSize: '1.25rem',
          lineHeight: '1.8rem',
        },

        '@media(max-width:800px)': {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      }}
    >
      {props.title}
      {props.href && (
        <LinkText to={props.href} underline='none'>
          ^
        </LinkText>
      )}
    </Typography>
  );
}
