import { ReactNode } from 'react';
import { SxProps, Typography } from '@mui/material';

type Props = {
  text: string;
  fontSize?: string;
  color?: string;
  sx?: SxProps;
};

export function RulesParagraph(props: Readonly<Props>): ReactNode {
  return (
    <Typography
      variant='body1'
      component='p'
      sx={{
        ...props.sx,
        fontFamily: 'Neuton',
        color: props.color || 'rgba(255, 255, 255, 0.7)',
        fontSize: props.fontSize || '1.1rem',
        letterSpacing: '0.16px',
        padding: '0 0 10px 0',
        fontWeight: '400',
        lineHeight: '1.375rem',
        '@media(max-width:5000px)': {
          fontSize: '2rem',
          lineHeight: '2.3rem',
        },

        '@media(max-width:3000px)': {
          fontSize: '1.5rem',
          lineHeight: '2rem',
        },

        '@media(max-width:1440px)': {
          fontSize: '1.25rem',
          lineHeight: '1.8rem',
        },

        '@media(max-width:800px)': {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      }}
    >
      {props.text}
    </Typography>
  );
}
