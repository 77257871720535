import { useState, ReactNode, useMemo } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PersonOff } from '@mui/icons-material';
import { t } from 'i18next';

import {
  Headline,
  InactiveDialog,
  ProfileDialog,
  ThestralDataGrid,
} from 'components/thestral';
import { Button, Dialog } from 'components/ui';

import { HouseList } from 'pages/PlayerCharacters/HouseList';
import { NpcList } from 'pages/NonPlayerCharacters/NpcList';

import { CharacterContext } from 'contexts';
import { Character, CharacterRevision, HouseType, NpcType } from 'types';
import { SearchBar } from 'components/ui/SearchBar';
import { EMPTY } from 'const';

type Props = {
  title: string;
  npcs?: boolean;
  dialogContent: ReactNode;
};

export function Characters(props: Readonly<Props>): ReactNode {
  const [char, setChar] = useState<Character>(undefined);
  const charContextValue = useMemo(() => ({ char, setChar }), [char, setChar]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [profileOpen, setProfileOpen] = useState<boolean>(false);
  const [inactiveOpen, setInactiveOpen] = useState<boolean>(false);
  const [filteredCharacters, setFilteredCharacters] =
    useState<CharacterRevision[]>(undefined);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const title = props.title;
  const breadcrumbs = [{ href: '/', title: t('Generic.Start') }];

  const onRowClick = (elem) => {
    setChar(elem);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
    setChar({ ...char, readonly: false });
  };

  return (
    <Box
      className='content'
      sx={{
        width: '100%',
        margin: '30px 50px 30px 0',
        '@media (max-width:600px)': {
          margin: '0',
        },
      }}
    >
      <Headline title={title} breadcrumbs={breadcrumbs} />
      {!props.npcs && (
        <SearchBar
          setFilteredCharacters={setFilteredCharacters}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {filteredCharacters ? (
        filteredCharacters.length === 0 ? (
          <Typography
            variant='body2'
            sx={{ fontSize: '2rem', textAlign: 'center' }}
          >
            {t('Generic.SearchNoResult')}
          </Typography>
        ) : (
          <Grid container sx={{ width: '100%' }}>
            <ThestralDataGrid
              rows={filteredCharacters || EMPTY}
              rowId={(row) => row.playerCharacter.id}
              columns={[
                {
                  field: 'name',
                  headerName: t('Generic.Name'),
                  sortable: true,
                  flex: 2,
                  valueGetter: (_, row) => row.playerCharacter.name,
                },

                {
                  field: 'playerCharacter.ageGroup',
                  headerName: isSm
                    ? t('Generic.AgeGroupShort')
                    : t('Generic.AgeGroup'),
                  sortable: true,
                  align: 'center',
                  headerAlign: 'center',
                  flex: 1,
                  valueGetter: (_, row) => {
                    const ageGroup = t(
                      `Enum.AgeGroup.${row.playerCharacter.ageGroup}`
                    );
                    return !row.playerCharacter.ageGroup
                      ? ''
                      : isSm
                      ? ageGroup[0]
                      : ageGroup;
                  },
                },
                {
                  field: 'playerCharacter.house',
                  headerName: t('Components.Characters.House'),
                  sortable: true,
                  align: 'right',
                  headerAlign: 'right',
                  flex: 1,
                  valueGetter: (_, row) => {
                    const house = t(`Enum.House.${row.playerCharacter.house}`);
                    return !row.playerCharacter.house
                      ? ''
                      : isSm
                      ? house[0]
                      : house;
                  },
                },
              ]}
              sx={{ width: '100%' }}
              loading={loading}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                },
              }}
              onRowClick={onRowClick}
            />
          </Grid>
        )
      ) : (
        <Grid container spacing={2}>
          {!props.npcs && (
            <Grid item xs={12}>
              <Button
                text={t('Generic.Inactive')}
                color='secondary'
                startIcon={<PersonOff />}
                onClick={() => setInactiveOpen(true)}
              />
            </Grid>
          )}
          {props.npcs
            ? ['TEACHER', 'STAFF', 'FOUNDER', 'GHOST', 'EXTERNAL'].map(
                (type: NpcType) => (
                  <Grid item xs={12} md={6} key={type}>
                    <NpcList
                      type={type}
                      onRowClick={onRowClick}
                      sx={{
                        marginLeft: '20px',
                        maxWidth: '700px',
                        minWidth: '10px',
                      }}
                    />
                  </Grid>
                )
              )
            : ['GRYFFINDOR', 'RAVENCLAW', 'HUFFLEPUFF', 'SLYTHERIN'].map(
                (house: HouseType) => (
                  <Grid item xs={12} md={6} key={house}>
                    <HouseList
                      house={house}
                      onRowClick={onRowClick}
                      sx={{
                        paddingLeft: '20px',
                        maxWidth: '700px',
                        minWidth: '10px',
                      }}
                    />
                  </Grid>
                )
              )}
        </Grid>
      )}

      <InactiveDialog
        open={inactiveOpen}
        onClose={() => setInactiveOpen(false)}
        onRowClick={onRowClick}
      />
      <CharacterContext.Provider value={charContextValue}>
        {open && char && (
          <Dialog
            sx={{
              '& .MuiPaper-root': {
                bgcolor: '#1e1e1e',
                marginBottom: '0',
              },
            }}
            open={open}
            onClose={() => closeDialog()}
            title={char.name}
            content={props.dialogContent}
            titleButton={
              char?.profileVisibility === 'FORALL' && (
                <Button
                  text='Profil'
                  color='secondary'
                  onClick={() => setProfileOpen(true)}
                />
              )
            }
          />
        )}
      </CharacterContext.Provider>

      {profileOpen && char && (
        <ProfileDialog
          open={profileOpen}
          onClose={() => setProfileOpen(false)}
          id={char?.userId}
          noDataProtection
          public
        />
      )}
    </Box>
  );
}
