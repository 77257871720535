import { AppBar, Box, ListItemText, MenuItem, Toolbar } from '@mui/material';
import { Button } from 'components/ui';
import { LogoutMenu } from './LogoutMenu';
import { BurgerMenu } from './BurgerMenu';
import { ReactNode } from 'react';

import { useAuth } from 'react-oidc-context';
import { Trans } from 'react-i18next';
import logo from '../../../assets/images/logo.svg';
import logo_rainbow from '../../../assets/images/logo_rainbow.png';

import { t } from 'i18next';
import { handleScrollToSection } from 'func';

type Props = {
  loggedIn: boolean;
};

export function Navigation(props: Readonly<Props>): ReactNode {
  const auth = useAuth();
  const orgaHasImages = false;

  return (
    <>
      {props.loggedIn ? (
        <AppBar position='fixed' color={'secondary'} sx={{ minHeight: '50px' }}>
          <Toolbar
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <BurgerMenu />
            <LogoutMenu />
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar
          position='fixed'
          sx={{
            minHeight: '50px',
            backgroundColor: 'rgba(38, 43, 43, 0.8)',
            backdropFilter: 'blur(5px)',
          }}
        >
          <Toolbar
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px 10px',
            }}
          >
            <Box
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth',
                })
              }
              sx={{ position: 'relative', height: '50px', witdth: 'auto' }}
            >
              <Box
                component='img'
                src={logo}
                width='auto'
                height='50px'
                sx={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  objectFit: 'contain',
                }}
              />
              <Box
                component='img'
                src={logo_rainbow}
                width='auto'
                height='50px'
                sx={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  objectFit: 'contain',
                  opacity: 0,
                  transition: 'opacity .5s',

                  ' &:hover': {
                    opacity: 1,
                  },
                }}
              />
            </Box>

            <Box sx={{ '@media(min-width: 700px)': { display: 'none' } }}>
              <BurgerMenu />
              <Button
                className='landing-btn'
                color='primary'
                onClick={() => auth.signinRedirect()}
                sx={{
                  width: '3rem',
                  fontSize: '1rem',
                  letterSpacing: '1px',
                  padding: '0px',
                }}
                text={<Trans i18nKey='Generic.Login' />}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                color: 'rgb(190 192 192)',
                '@media(max-width: 700px)': { display: 'none' },
              }}
            >
              <MenuItem onClick={() => handleScrollToSection('aboutUs')}>
                <ListItemText
                  primary={t('Generic.AboutUs')}
                  primaryTypographyProps={{
                    fontSize: { xs: '1.25rem', md: '1.5rem', xl: '1.8rem' },
                    color: 'rgb(190 192 192)',
                  }}
                />
              </MenuItem>
              <MenuItem onClick={() => handleScrollToSection('setting')}>
                <ListItemText
                  primary={t('Components.Rules.WorldBuilding.Setting')}
                  primaryTypographyProps={{
                    fontSize: { xs: '1.25rem', md: '1.5rem', xl: '1.8rem' },
                    color: 'rgb(190 192 192)',
                  }}
                />
              </MenuItem>
              <MenuItem onClick={() => handleScrollToSection('coc')}>
                <ListItemText
                  primary={t('Generic.CodeOfConduct')}
                  primaryTypographyProps={{
                    fontSize: { xs: '1.25rem', md: '1.5rem', xl: '1.8rem' },
                    color: 'rgb(190 192 192)',
                  }}
                />
              </MenuItem>
              {orgaHasImages && (
                <MenuItem onClick={() => handleScrollToSection('orga')}>
                  <ListItemText
                    primary={t('Generic.Orga')}
                    primaryTypographyProps={{
                      fontSize: { xs: '1.25rem', md: '1.5rem', xl: '1.8rem' },
                      color: 'rgb(190 192 192)',
                    }}
                  />
                </MenuItem>
              )}
              <MenuItem onClick={() => handleScrollToSection('waitinglist')}>
                <ListItemText
                  primary={t('Components.Admin.Users.WaitingList')}
                  primaryTypographyProps={{
                    fontSize: { xs: '1.25rem', md: '1.5rem', xl: '1.8rem' },
                    color: '#eab354',
                  }}
                />
              </MenuItem>

              <Button
                color='primary'
                onClick={() => auth.signinRedirect()}
                sx={{
                  '@media(max-width:5000px)': {
                    fontSize: '1.8rem',
                    lineHeight: '2.3rem',
                    width: '150px',
                    margin: '10px',
                  },

                  '@media(max-width:1440px)': {
                    fontSize: '1.5rem',
                    lineHeight: '2.rem',
                    width: '120px',
                    margin: '10px',
                  },

                  '@media(max-width:800px)': {
                    fontSize: '1.25rem',
                    width: '100px',
                    margin: '10px',
                  },

                  letterSpacing: '1px',
                  padding: '0px',
                }}
                text={<Trans i18nKey='Generic.Login' />}
              />
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}
