import { ReactNode } from 'react';
import { DialogActions, DialogTitle, Dialog as MuiDialog, DialogContent, SxProps, Typography, AppBar, IconButton, Toolbar, Grid } from '@mui/material';

import { ThestralDialogTransition } from 'components/thestral';
import { CloseButton } from 'components/ui';
import { ArrowBackIosNew } from '@mui/icons-material';

type Props = {
    open: boolean;
    onClose?: () => void;
    content?: ReactNode;
    title?: string;
    titleRightIcon?: ReactNode;
    caption?: string;
    actions?: ReactNode;
    fullWidth?: boolean;
    fullScreen?: boolean;
    sx?: SxProps;
    noActions?: boolean;
    width?: string;
    titleButton?: ReactNode;
    onKeyDown?: (event) => void;
};

export function Dialog(props: Readonly<Props>): ReactNode {
    return (
        <MuiDialog
            sx={{
                ...props.sx,
                '& .MuiDialog-paper': {
                    backgroundColor: '#1e1e1e',
                    marginBottom: '0',
                    minWidth: '300px',
                    maxWidth: '1800px',
                    width: '90vw',
                    padding: '10px',
                },
            }}
            open={props.open}
            onClose={props.onClose}
            onKeyDown={props.onKeyDown}
            fullWidth={props.fullWidth}
            TransitionComponent={ThestralDialogTransition}
            fullScreen={props.fullScreen}
        >
            {props.fullScreen ? (
                <AppBar
                    color='secondary'
                    sx={{
                        position: 'relative',
                    }}
                >
                    <Toolbar>
                        <IconButton autoFocus edge='start' color='inherit' onClick={props.onClose} aria-label='close'>
                            <ArrowBackIosNew color='primary' />
                        </IconButton>
                        <Typography
                            sx={{
                                ml: 2,
                                flex: 1,
                                '@media (max-width:600px)': {
                                    fontSize: '1rem',
                                },
                            }}
                            variant='h6'
                            component='div'
                        >
                            {props.title}
                        </Typography>

                        <CloseButton onClick={props.onClose} />
                    </Toolbar>
                </AppBar>
            ) : (
                <DialogTitle sx={{ paddingBottom: '0px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant='h5' component='p' sx={{ color: '#bcbcbc' }}>
                                {props.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ justifyContent: 'end', display: 'flex' }}>
                            {props.titleButton}
                        </Grid>
                    </Grid>
                    {props.caption && (
                        <Typography variant='caption' component='span' color='gray'>
                            {props.caption}
                        </Typography>
                    )}
                </DialogTitle>
            )}
            <DialogContent
                sx={{
                    '@media (max-width:600px)': {
                        padding: 0,
                    },
                }}
            >
                {props.content}
            </DialogContent>
            {!props.noActions && (
                <DialogActions
                    sx={{
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                    }}
                >
                    {props.actions}
                    <CloseButton onClick={props.onClose} />
                </DialogActions>
            )}
        </MuiDialog>
    );
}
