import { useAuth } from 'react-oidc-context';
import { Box, Card, Tab, Typography, Grid, Paper } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Parallax } from 'components/ui/Parallax';
import { Headline, RulesTab } from 'components/thestral';
import { t } from 'i18next';
import { Button } from 'components/ui';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { RuleComponent } from 'types';
import { JsonContext } from 'contexts';
import DownloadIcon from '@mui/icons-material/Download';
import { ORGA } from 'const';
import { WaitingListForm } from 'components/thestral/WaitingListForm';

export function LandingPage() {
  const nav = useNavigate();
  const auth = useAuth();

  const [cocTab, setCOCTab] = useState('0');
  const [json, setJson] = useState();
  const jsonContextValue = useMemo(() => ({ json, setJson }), [json, setJson]);
  const orgaHasImages = false;
  const designDocumentIsReady = false;
  const cocItems = [
    {
      name: t('Components.Rules.CodeOfConduct.Generic'),
      component: <RulesTab slug='generic' ruleType='CodeOfConduct' publicLoc />,
    },
    {
      name: t('Components.Rules.CodeOfConduct.Community'),
      component: (
        <RulesTab slug='community' ruleType='CodeOfConduct' publicLoc />
      ),
    },
    {
      name: t('Components.Rules.CodeOfConduct.Orga'),
      component: <RulesTab slug='orga' ruleType='CodeOfConduct' publicLoc />,
    },
  ] as RuleComponent[];

  const cardStyle = {
    backgroundColor: '#262b2b',
    padding: '50px',
    paddingTop: '0px',
    textWrap: 'balance',
    '@media (max-width:600px)': {
      padding: '20px',
      paddingTop: '0px',
    },
  };

  const paragraphStyle = {
    marginBottom: '20px',
    display: 'inline-block',
    textWrap: 'balance',
    '@media(max-width:5000px)': {
      fontSize: '2rem',
      lineHeight: '2.3rem',
    },

    '@media(max-width:3000px)': {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },

    '@media(max-width:1440px)': {
      fontSize: '1.25rem',
      lineHeight: '1.8rem',
    },

    '@media(max-width:800px)': {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
  };

  useEffect(() => {
    if (!auth.isLoading && auth.isAuthenticated) {
      nav('/home');
    }
  }, [auth.isAuthenticated, auth.isLoading]);

  return (
    <Box
      sx={{
        zIndex: 2,
        backgroundColor: '#151718',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '50px',
      }}
    >
      <Parallax title={'Generic.Title'} caption={'Generic.Subtitle'} />
      <Box
        id='landing-page-content'
        sx={{
          padding: '50px',
          width: '100vw',
          maxWidth: '1800px',
          display: 'flex',
          gap: '50px',
          flexDirection: 'column',
          '@media (max-width:600px)': {
            padding: '20px',
            gap: '30px',
          },
        }}
      >
        <Box id='aboutUs'>
          <Headline title={t('Generic.AboutUs')} />
          <Card sx={cardStyle}>
            <Typography variant='caption' sx={paragraphStyle}>
              Das Thestral LARP ist eine Veranstaltungsreihe, die dich in die
              Wizarding World des Jahres 1999 entführt. Zweimal im Jahr
              verwandeln wir das Schullandheim Waldmannshausen für drei Tage in
              eine Schule für Hexerei und Magie. Unsere rund 60 bunten und
              vielfältigen Teilnehmenden schlüpfen in die Rollen von
              Schüler*innen des 4. oder 5. Jahrgangs und stellen sich
              Herausforderungen und Fragestellungen, die weit über das Spiel
              hinausgehen und ins echte Leben übertragen werden können.
            </Typography>
            <Typography variant='caption' sx={paragraphStyle}>
              Neben den typischen Aspekten eines Akademie-LARPs behandeln wir
              tiefgründige Themen einer Nachkriegsgeneration, wie Trauma und
              Verlust, sowie geschichtliche Aspekte und die magische
              Funktionalität der Wizarding World. Doch das ist noch nicht alles!
              Unser Community-Ansatz ermöglicht es den Teilnehmenden, sich in
              verschiedenen Teams zu engagieren und ihre Interessen und
              Kenntnisse in Bereichen wie Gewandung, Kommunikation, Finanzen,
              Ambiente, unsere Welt, Sicherheitsmechanismen und Regelwerk
              einzubringen.
            </Typography>
            <Typography variant='caption' sx={paragraphStyle}>
              Wir distanzieren uns ausdrücklich von den Aussagen und
              Einstellungen der Autorin der zugrunde liegenden Buchreihe. Hinter
              der Organisation unserer Veranstaltungen steht der gemeinnützige
              Verein für Freilichtimprovisation und Persönlichkeitsentwicklung
              (VFP). Unsere Events sind im Bereich des Educational Live Action
              Roleplay (kurz Edu-LARP) angesiedelt, denn wir nutzen das
              Rollenspiel, um Bildungs- und Lernprozesse zu fördern.
            </Typography>
          </Card>
        </Box>
        <Box id='setting'>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            <Headline title={t('Components.Rules.WorldBuilding.Setting')} />
            {designDocumentIsReady && (
              <Button
                color='secondary'
                onClick={() => {}}
                sx={{
                  width: 'auto',
                  fontSize: '1rem',
                  letterSpacing: '1.2px',
                  padding: '4px 6px',
                  marginTop: '10px',
                  '@media (max-width:400px)': {
                    fontSize: '0.8rem',

                    margin: '5px 0 20px 0',
                  },
                }}
                startIcon={<DownloadIcon />}
                text={t('Components.Rules.DesignDocument')}
              />
            )}
          </Box>
          <Card sx={cardStyle}>
            <Typography variant='caption' sx={paragraphStyle}>
              Es ist Neujahr 1999, als sich die Türen zu Hogwarts zum ersten Mal
              nach dem Krieg wieder öffnen. Mit einer fulminanten Neujahrsfeier,
              an der unter anderem auch Vertreter*innen des Ministeriums und der
              Presse beteiligt sind, wird das neue Schuljahr und eine neue Ära
              für die magische Gemeinschaft eingeläutet. Doch die Narben sind
              trotz der Bemühungen der Auror*innen, Fluchbrecher*innen und des
              Lehrkörpers immer noch deutlich zu erkennen. Für Erstklässler ist
              Hogwarts deshalb kein sicherer Ort.
            </Typography>
            <Typography variant='caption' sx={paragraphStyle}>
              Zudem hat der Krieg und die Zeit davor dafür gesorgt, dass viele
              Schüler*innen Hogwarts während des Jahrs verlassen mussten, gar
              nicht erst das Schuljahr 1997/98 angetreten haben oder ihren
              Studien nicht angemessen nachgehen konnten. Ganz davon abgesehen,
              dass die Lehrinhalte mancher Lehrkräfte stark von dem abgewichen
              waren, was der Lehrplan vorgegeben hätte. Das Schuljahr 1997/98
              wird daher wiederholt, um allen Schüler*innen die gleichen Chancen
              zu bieten. Neue Erstklässler werden in diesem Jahr nicht auf
              Hogwarts aufgenommen. Alle geleisteten Prüfungen im Schuljahr
              97/98 werden für ungültig erklärt.
            </Typography>
            <Typography variant='caption' sx={paragraphStyle}>
              Die vier Monate (September bis Dezember&apos;98), die man durch
              die Reparaturarbeiten am Schloss verloren hat, lassen sich nicht
              in Gänze kompensieren. Die Sommerferien des Folgejahres &apos;99
              werden um einen Monat verkürzt und die Lehrkräfte sind angehalten,
              ihre Lehrinhalte an die verringerte Dauer des Schuljahres und die
              individuellen Bedürfnisse der Schüler*innen anzupassen. Nur wenige
              Schüler*innen der 7.Klassen kehren nach Hogwarts zurück. Die
              meisten haben sich entschieden, das Angebot des Ministeriums
              anzunehmen, ihren Abschluss ehrenhalber erteilt zu bekommen.
            </Typography>
          </Card>
        </Box>
        <Box id='coc'>
          <Headline title={t('Generic.CodeOfConduct')} marginBottom='20px' />
          <Card
            sx={{
              backgroundColor: '#262b2b',
              position: 'relative',
              '@media (min-width:600px)': {
                padding: '20px',
              },
            }}
          >
            <TabContext value={cocTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={(_, newValue) => setCOCTab(newValue)}
                  textColor='primary'
                  variant='scrollable'
                  scrollButtons={true}
                  allowScrollButtonsMobile
                >
                  {cocItems.map((item, i) => (
                    <Tab
                      label={item.name}
                      value={i.toString()}
                      disabled={item.disabled}
                      key={item.name + '-tab'}
                      sx={{
                        color: 'rgb(190 192 192)',
                        '@media(max-width:5000px)': {
                          fontSize: '2rem',
                          lineHeight: '2.3rem',
                        },

                        '@media(max-width:3000px)': {
                          fontSize: '1.5rem',
                          lineHeight: '2rem',
                        },

                        '@media(max-width:1440px)': {
                          fontSize: '1.25rem',
                          lineHeight: '1.8rem',
                        },

                        '@media(max-width:800px)': {
                          fontSize: '1rem',
                          lineHeight: '1.5rem',
                        },
                      }}
                    />
                  ))}
                </TabList>
              </Box>

              <JsonContext.Provider value={jsonContextValue}>
                {cocItems.map((item, i) => (
                  <TabPanel
                    key={item.name + '-panel'}
                    value={i.toString()}
                    sx={{
                      '@media(max-width:5000px)': {
                        fontSize: '2.5rem',
                        lineHeight: '2.8rem',
                      },

                      '@media(max-width:1440px)': {
                        fontSize: '2rem',
                        lineHeight: '2.3rem',
                      },

                      '@media(max-width:800px)': {
                        fontSize: '1.5rem',
                        lineHeight: '1.7rem',
                      },
                      '@media(max-width:600px)': {
                        fontSize: '1rem',
                        lineHeight: '1.2rem',
                      },
                    }}
                  >
                    {item.component}
                  </TabPanel>
                ))}
              </JsonContext.Provider>
            </TabContext>
          </Card>
        </Box>
        {orgaHasImages && (
          <Box id='orga'>
            <Headline title={t('Generic.Orga')} marginBottom='20px' />
            <Card
              sx={{
                backgroundColor: '#262b2b',
                padding: '50px',
              }}
            >
              <Grid
                container
                gap='20px'
                sx={{ justifyContent: 'space-evenly' }}
              >
                {ORGA.map((person) => (
                  <Grid key={`orga-${person.name}`} id={`orga-${person.name}`}>
                    <Paper elevation={3} sx={{ position: 'relative' }}>
                      <Box
                        component='img'
                        src={person.img}
                        sx={{
                          objectFit: 'cover',
                          display: 'block',
                          width: '250px',
                          height: '450px',

                          '@media(max-width: 360px)': {
                            width: '200px',
                            height: '400px',
                          },
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: '0px',
                          left: '0px',
                          padding: '10px',
                          backgroundColor: 'rgba(37, 38, 41, 0.742)',
                          width: '100%',
                          minHeight: '120px',
                        }}
                      >
                        <Typography
                          variant='h1'
                          sx={{
                            fontSize: '2rem',
                            fontWeight: '400',
                            letterSpacing: '1px',
                            marginBottom: '10px',
                            marginRight: '10px',
                            '@media(max-width: 360px)': {
                              fontSize: '1.5rem',
                            },
                          }}
                        >
                          {person.name}
                          <Typography
                            variant='caption'
                            sx={{
                              fontSize: '1.5rem',
                              '@media(max-width: 360px)': {
                                fontSize: '1rem',
                              },
                            }}
                          >
                            {`(${person.pronouns})`}
                          </Typography>
                        </Typography>

                        <Typography
                          variant='caption'
                          sx={{
                            color: '#f4f4f4',
                            fontSize: '1.5rem',
                          }}
                        >
                          {person.resort} {person.SL && '| KampagnenSL'}
                        </Typography>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Box>
        )}
        <Box id='waitinglist'>
          <Headline
            title={t('Components.WaitingList.Caption')}
            marginBottom='20px'
          />
          <Card sx={cardStyle}>
            <WaitingListForm />
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
