import { Box } from '@mui/material';
import Wallpaper from 'assets/images/thestral_bg.png';
import { ReactNode } from 'react';

type Props = {
  isLandingPage?: boolean;
};

export function Background(props: Readonly<Props>): ReactNode {
  if (props.isLandingPage) {
    return (
      <Box
        sx={{
          position: 'fixed',
          inset: 0,
          zIndex: '0',
          backgroundColor: '#151718',
        }}
      />
    );
  } else {
    return (
      <Box
        component='img'
        sx={{
          position: 'fixed',
          inset: 0,
          zIndex: '-10',
        }}
        src={Wallpaper}
      />
    );
  }
}
