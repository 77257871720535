import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { country_codes } from 'const';
import { t } from 'i18next';

type Props = {
  value?: string[];
  onChange?: (string) => void;
  readOnly?: boolean;
  error?: boolean;
};

export function CountryCodeSelect(props: Readonly<Props>) {
  return (
    <FormControl
      variant='standard'
      required
      sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          borderBottom: '1px solid #fff',
          height: 'inherit',
        },
        '& .MuiSelect-select': {
          color: '#fff',
          fontFamily: 'Neuton',
        },
        label: {
          fontSize: '1.1rem',
          fontFamily: 'Neuton',
          color: 'rgba(255, 255, 255, 0.7)',
        },
      }}
      error={props.error}
    >
      <InputLabel id='country-label'>
        {t('Components.Profile.PersonalData.LegalData.Country')}
      </InputLabel>
      <Select
        labelId='country-label'
        name='country'
        id='country'
        inputProps={{ readOnly: props.readOnly }}
        label={t('Components.Profile.PersonalData.LegalData.Country')}
        value={props.value || ''}
        onChange={props.onChange}
        required
        sx={{
          height: '60px',
          minWidth: '100px',
          '& .MuiSvgIcon-root': {
            color: 'primary.main',
          },
        }}
        MenuProps={{
          slotProps: {
            paper: {
              style: {
                backgroundColor: '#1f1f1f',
              },
            },
          },
        }}
      >
        {country_codes.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{
              backgroundColor: '#1e1e1e',
              color: '#fff',
              fontFamily: 'Neuton',
              '&:hover': {
                backgroundColor: '#303030',
              },
            }}
            component='li'
          >
            {t(`Enum.Country.${item.country}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
