import { Box, Checkbox, Grid, Typography } from '@mui/material';

import { Button, TextField } from 'components/ui';
import { EVENTS_URL, HTTP_METHOD } from 'const';
import { useFetchFromBackend, useSnackbar } from 'func';
import { t } from 'i18next';

interface TriggerText {
  trigger: string;
  undesired: string;
}

type Props = {
  isWarning?: boolean;
  isSignup: boolean;
  triggerConfirm: boolean;
  setTriggerConfirm: (value: boolean) => void;
  triggerRefetch: () => void;
  setLoading: (value: boolean) => void;
  triggertext: TriggerText;
  setTriggertext: (value) => void;
  loading: boolean;
  eventId: number;
  userId?: number;
  refetchParticipants?: () => void;
  setOpen?: (value: boolean) => void;
};

export function TriggerContent(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();

  function submitTrigger() {
    const url = props.isWarning
      ? `${EVENTS_URL}/${props.eventId}/signup/${props.userId}`
      : `${EVENTS_URL}/${props.eventId}/ban`;
    const method = props.isWarning ? HTTP_METHOD.PUT : HTTP_METHOD.POST;

    const body = JSON.stringify({
      trigger: props.triggertext.trigger,
      undesired: props.triggertext.undesired,
      triggerConfirm: props.triggerConfirm,
    });
    props.setLoading(true);
    fetchFromBackend(url, {
      method: method,
      body: body,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;
        if (response.ok) {
          props.triggerRefetch();
          props.setTriggertext({ trigger: '', undesired: '' });
          props.refetchParticipants();
          props.setOpen(false);
        }
        showSnackbar(
          `${t('Components.Events.TriggerUpdateTitle')} ${result}`,
          severity
        );
      })
      .finally(() => props.setLoading(false));
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {props.isSignup ? (
        <>
          <Typography
            variant='ruleTitle'
            sx={{ fontSize: '1.5rem', paddingTop: '10px' }}
          >
            {t('Components.Events.SignUp.WarningTitle')}
          </Typography>
          <Typography variant='caption' sx={{ marginBottom: '10px' }}>
            {t('Components.Events.SignUp.Warning1')}
          </Typography>
          <Typography variant='ruleTitle' sx={{ paddingTop: '10px' }}>
            {t('Components.Events.SignUp.WarningTitle2')}
          </Typography>
          <Typography variant='caption' sx={{ marginBottom: '10px' }}>
            {t('Components.Events.SignUp.Warning2')}
          </Typography>
          <Typography variant='ruleTitle' sx={{ paddingTop: '10px' }}>
            {t('Components.Events.SignUp.WarningTitle3')}
          </Typography>
          <Typography variant='caption' sx={{ marginBottom: '10px' }}>
            {t('Components.Events.SignUp.Warning3')}
          </Typography>
          <Typography variant='ruleTitle' sx={{ paddingTop: '10px' }}>
            {t('Components.Events.SignUp.WarningTitle4')}
          </Typography>
          <Typography variant='caption'>
            {t('Components.Events.SignUp.Warning4')}
          </Typography>
        </>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='ruleTitle' sx={{ fontSize: '1.5rem' }}>
            {t('Components.Events.TriggerUpdateTitle')}
          </Typography>
          <Typography variant='caption'>
            {t('Components.Events.TriggerUpdate')}
          </Typography>
        </Box>
      )}
      <Grid container gap={2}>
        <Grid item xs={12} md={5}>
          <TextField
            id='triggertext'
            label={t('Components.Events.SignUp.Trigger')}
            value={props.triggertext.trigger}
            multiline
            helperText={t('Components.Events.SignUp.TriggerHelp')}
            rows={2}
            sx={{
              width: '100%',
              input: { color: '#fff' },
              marginBottom: '20px',
            }}
            onChange={(e) =>
              props.setTriggertext({
                ...props.triggertext,
                trigger: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            id='undesiredtext'
            label={t('Components.Events.SignUp.Undesired')}
            value={props.triggertext.undesired}
            multiline
            rows={2}
            sx={{
              width: '100%',
              input: { color: '#fff' },
              marginBottom: '10px',
            }}
            onChange={(e) =>
              props.setTriggertext({
                ...props.triggertext,
                undesired: e.target.value,
              })
            }
          />
        </Grid>
        {props.isSignup ? (
          <Grid xs={12}>
            <Checkbox
              id='triggerConfirm'
              sx={{ outline: '#fff' }}
              onChange={() => props.setTriggerConfirm(!props.triggerConfirm)}
              checked={props.triggerConfirm}
            />
            <Typography variant={'caption'} sx={{ color: '#eab354' }}>
              {t('Components.Events.SignUp.TriggerConfirm')}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Button
              text={t('Components.Events.TriggerSubmit')}
              onClick={submitTrigger}
            />
          </Grid>
        )}
        {props.isWarning && (
          <Grid item xs={12}>
            <Button
              text={t('Components.Events.TriggerSubmit')}
              onClick={submitTrigger}
              disabled={!props.triggerConfirm}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
