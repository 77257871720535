import { t } from 'i18next';
import { Box, Divider, List } from '@mui/material';
import {
  Castle,
  ContactPage,
  Gavel,
  Groups,
  HistoryEdu,
  Person,
} from '@mui/icons-material';

import { MenuItem } from 'components/ui';
import { handleScrollToSection } from 'func';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  setDrawer: Dispatch<SetStateAction<boolean>>;
};

export function LandingPageItems(props: Readonly<Props>) {
  function handleNavigateLandingPage(sectionId) {
    props.setDrawer(false);
    handleScrollToSection(sectionId);
  }
  const orgaHasImages = false;
  const designDocumentIsReady = false;

  return (
    <Box sx={{ width: '256px' }}>
      <List>
        <Divider sx={{ borderColor: '#ffffff1f' }} />
        <MenuItem
          icon={<Person />}
          text={t('Generic.AboutUs')}
          onClick={() => handleNavigateLandingPage('aboutUs')}
        />
        <Divider sx={{ borderColor: '#ffffff1f' }} />
        <MenuItem
          icon={<Castle />}
          text={t('Components.Rules.WorldBuilding.Setting')}
          onClick={() => handleNavigateLandingPage('setting')}
        />
        <Divider sx={{ borderColor: '#ffffff1f' }} />
        <MenuItem
          icon={<Gavel />}
          text={t('Generic.CodeOfConduct')}
          onClick={() => handleNavigateLandingPage('coc')}
        />
        <Divider sx={{ borderColor: '#ffffff1f' }} />
        {orgaHasImages && (
          <MenuItem
            icon={<Groups />}
            text={t('Generic.Orga')}
            onClick={() => handleNavigateLandingPage('orga')}
          />
        )}
        <Divider sx={{ borderColor: '#ffffff1f' }} />
        {designDocumentIsReady && (
          <MenuItem
            icon={<HistoryEdu />}
            text={t('Components.Rules.DesignDocument')}
            to='#DesignDocument'
            onClick={() => {}}
          />
        )}
        <Divider sx={{ borderColor: '#ffffff1f' }} />
        <MenuItem
          icon={<ContactPage />}
          text={t('Components.Admin.Users.WaitingList')}
          onClick={() => handleNavigateLandingPage('waitinglist')}
        />
      </List>
    </Box>
  );
}
