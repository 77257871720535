import { useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { Box, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import {
  Headline,
  PersonalData,
  DataProtectionData,
} from 'components/thestral';
import { Button, Dialog } from 'components/ui';

import { HTTP_METHOD, USERS_URL } from 'const';
import { useFetch, useFetchFromBackend, useSnackbar } from 'func';
import { User } from 'types';

import { useFormik } from 'formik';

export function Profile(): ReactNode {
  const auth = useAuth();
  const fetchFromBackend = useFetchFromBackend();
  const email = auth.user?.profile.email;
  const { data } = useFetch<User>(`${USERS_URL}/by-email/${email}`);
  const [pendingValues, setPendingValues] = useState({});

  const [open, setOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      id: data?.id,
      profile: {
        nickname: data?.profile?.nickname,
        pronouns: data?.profile?.pronouns,
        socialMediaAccounts: {
          SPOTIFY: data?.profile?.socialMediaAccounts?.SPOTIFY,
          PINTEREST: data?.profile?.socialMediaAccounts?.PINTEREST,
          INSTAGRAM: data?.profile?.socialMediaAccounts?.INSTAGRAM,
        },
        allergies: data?.profile?.allergies,
        phobiae: data?.profile?.phobiae,
        eatingHabits: data?.profile?.eatingHabits,
        dataVisibility: data?.profile?.dataVisibility || '',
        imageRights: data?.profile?.imageRights || '',
      },
      dateOfBirth: data?.dateOfBirth,
      email: data?.email,
      firstname: data?.firstname,
      lastname: data?.lastname,
      streetName: data?.streetName,
      streetNumber: data?.streetNumber,
      addressOptional: data?.addressOptional,
      zipCode: data?.zipCode,
      city: data?.city,
      country: data?.country,
      readonly: false,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => trySave(values),
  });

  const nav = useNavigate();

  const title = t('Components.Profile.Title');
  const breadcrumbs = [{ href: '/', title: t('Generic.Start') }];

  const trySave = (values) => {
    email != values.email ? pendingSave(values) : save(values);
  };

  const pendingSave = (values) => {
    setOpen(true);
    setPendingValues(values);
  };

  const save = (values) => {
    const user = values;

    const promises = [];
    const account = fetchFromBackend(`${USERS_URL}/${user.id}`, {
      method: HTTP_METHOD.PUT,
      body: JSON.stringify(user),
    })
      .then((response) => response)
      .catch((error) => console.error(error));
    const profile = fetchFromBackend(`${USERS_URL}/${user.id}/profile`, {
      method: HTTP_METHOD.PUT,
      body: JSON.stringify(user['profile']),
    })
      .then((response) => response)
      .catch((error) => console.error(error));
    promises.push(account);
    promises.push(profile);

    if (email != user['email']) {
      const email = fetchFromBackend(`${USERS_URL}/${user.id}/email`, {
        method: HTTP_METHOD.PUT,
        body: JSON.stringify({ email: user['email'] }),
      })
        .then((response) => response)
        .catch((error) => console.error(error));
      promises.push(email);
    }

    Promise.allSettled(promises).then((res) => {
      showSnackbar(t('Components.Profile.Saved'), 'success');
      setOpen(false);

      if (res.length > 2) {
        setTimeout(() => {
          auth.signoutSilent();
          nav('/');
        }, 2000);
      }
    });
  };

  function actions(): ReactNode {
    return (
      <Button
        onClick={() => save(pendingValues)}
        text={t('Generic.ConfirmSave')}
        color='success'
      />
    );
  }

  function content(): ReactNode {
    return (
      <Typography variant='h6' component='p'>
        <Trans i18nKey='Components.Profile.Dialog.Content' />
      </Typography>
    );
  }

  return (
    <>
      <Box className='content' sx={{ marginTop: '50px' }}>
        <Headline title={title} breadcrumbs={breadcrumbs} />
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} sx={{ padding: '20px' }}>
            <PersonalData formik={formik} />
            <DataProtectionData formik={formik} />
          </Grid>
          <Button
            text={t('Generic.Save')}
            onClick={formik.submitForm}
            color='success'
          />
        </form>
      </Box>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          title={t('Components.Profile.Dialog.Title')}
          content={content()}
          actions={actions()}
        />
      )}
    </>
  );
}
